/* import __COLOCATED_TEMPLATE__ from './modifier-explanation.hbs'; */
import Component from '@glimmer/component';
import {
  Modifier,
  ModifierType,
} from 'teamtailor/components/stage/odds-of-hiring/index';

interface ModifierExplanationArgs {
  modifiers: Modifier[];
}

export default class ModifierExplanationComponent extends Component<ModifierExplanationArgs> {
  intlPathFor = (modifierType: ModifierType) =>
    `job.odds_of_hiring.hiring_forecast.modifier.modifier_type_${modifierType.toLowerCase()}`;

  intlDescriptionPathFor = (modifierType: ModifierType) =>
    `job.odds_of_hiring.hiring_forecast.modifier.modifier_description_${modifierType.toLowerCase()}`;
}
