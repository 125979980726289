import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import { rtlLanguageCodes } from 'teamtailor/utils/rtl-languages';
import { get } from 'teamtailor/utils/get';
import { fetchGroupedAssetsByContext } from 'teamtailor/utils/filter-assets';
import { AddonFeature } from 'types/features';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import StageTypeModel, {
  UNEDITABLE_STAGE_TYPES,
} from 'teamtailor/models/stage-type';
import IntlService from 'ember-intl/services/intl';
import FlipperService from 'teamtailor/services/flipper';
import Server from 'teamtailor/services/server';
import ApprovalSettingModel from './approval-setting';
import AuditLogModel from './audit-log';
import {
  AutoJoinDomainModel,
  AutomaticDeletionSettingsModel,
  AvatarModel,
  BatchJobModel,
  BlogModel,
  CannedResponseModel,
  CareerSiteModel,
  ChannelActivationModel,
  CookieSettingModel,
  CopilotSettingModel,
  CustomFieldModel,
  DashboardSettingModel,
  DashboardWidgetModel,
  DataPrivacySettingModel,
  DataRequestSettingModel,
  DefaultNotificationConfigModel,
  DepartmentModel,
  DesignModel,
  DivisionModel,
  DomainModel,
  EeoReportSettingModel,
  EmployeeDashboardModel,
  FeatureModel,
  GroupSiteCompanyModel,
  ImageModel,
  InterviewKitModel,
  JobModel,
  LinkedinSettingModel,
  LocationModel,
  MeetingRoomModel,
  MessageWidgetModel,
  NpsResponseModel,
  PageModel,
  PartnerActivationModel,
  PaymentProcessorSettingModel,
  PhoneNumberModel,
  PickedDashboardWidgetModel,
  PickedQuestionModel,
  PrivacyPolicyModel,
  QuestionModel,
  RecruitingFirmModel,
  ReferenceTemplateModel,
  RegionModel,
  RequisitionArchiveReasonModel,
  RequisitionFlowModel,
  RequisitionModel,
  RequisitionSettingModel,
  RoleModel,
  SandboxActivationModel,
  ScorecardCriteriumModel,
  SectionModel,
  SingleSignOnModel,
  SlackAppModel,
  SmsSettingModel,
  SourceModel,
  StageNameModel,
  SubscriptionContractModel,
  SubscriptionInvoiceModel,
  SurveyModel,
  TeamModel,
  TermsOfServiceSettingModel,
  UserModel,
  UserTemplateModel,
  WalletModel,
  WebhookSubscriptionModel,
} from 'teamtailor/models';
import Raw from 'teamtailor/transforms/raw';
import PhoneNumberSetupModel from './phone-number-setup';
import { NO_DIVISION_ID } from './division';
import DepartmentCountModel from './department-count';
import RoleCountModel from './role-count';
import DivisionCountModel from './division-count';

export default class CompanyModel extends Model {
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare server: Server;

  @belongsTo('approval-setting')
  declare approvalSetting: AsyncBelongsTo<ApprovalSettingModel>;

  @belongsTo('audit-log') declare auditLog: AsyncBelongsTo<AuditLogModel>;
  @belongsTo('automatic-deletion-setting')
  declare automaticDeletionSetting: AsyncBelongsTo<AutomaticDeletionSettingsModel>;

  @belongsTo('blog') declare blog: AsyncBelongsTo<BlogModel>;

  @belongsTo('company') declare twilio: AsyncBelongsTo<CompanyModel>; // NOTE: Hmmmm???
  @belongsTo('cookie-setting')
  declare cookieSetting: AsyncBelongsTo<CookieSettingModel>;

  @belongsTo('dashboard-setting')
  declare dashboardSetting: AsyncBelongsTo<DashboardSettingModel>;

  @belongsTo('data-privacy-setting')
  declare dataPrivacySetting: AsyncBelongsTo<DataPrivacySettingModel>;

  @belongsTo('data-request-setting')
  declare dataRequestSetting: AsyncBelongsTo<DataRequestSettingModel>;

  @belongsTo('department-count')
  declare departmentCount: AsyncBelongsTo<DepartmentCountModel>;

  @belongsTo('design') declare design: AsyncBelongsTo<DesignModel>;
  @belongsTo('design') declare draftDesign: AsyncBelongsTo<DesignModel>;

  @belongsTo('division-count')
  declare divisionCount: AsyncBelongsTo<DivisionCountModel>;

  @belongsTo('domain') declare domain: AsyncBelongsTo<DomainModel>;
  @belongsTo('eeo-report-setting')
  declare eeoReportSetting: AsyncBelongsTo<EeoReportSettingModel>;

  @belongsTo('employee-dashboard')
  declare employeeDashboard: AsyncBelongsTo<EmployeeDashboardModel>;

  @belongsTo('linkedin-setting')
  declare linkedinsetting: AsyncBelongsTo<LinkedinSettingModel>;

  @belongsTo('message-widget')
  declare globalMessageWidget: AsyncBelongsTo<MessageWidgetModel>;

  @belongsTo('payment-processor-setting')
  declare paymentProcessorSetting: AsyncBelongsTo<PaymentProcessorSettingModel>;

  @belongsTo('location')
  declare headquarters: AsyncBelongsTo<LocationModel>;

  @belongsTo('phone-number')
  declare phoneNumber: AsyncBelongsTo<PhoneNumberModel>;

  @belongsTo('phone-number-setup')
  declare phoneNumberSetup: AsyncBelongsTo<PhoneNumberSetupModel>;

  @belongsTo('privacy-policy')
  declare privacyPolicy: AsyncBelongsTo<PrivacyPolicyModel>;

  @belongsTo('requisition-setting', { async: false })
  declare requisitionSetting: RequisitionSettingModel;

  @belongsTo('role-count')
  declare roleCount: AsyncBelongsTo<RoleCountModel>;

  @belongsTo('single-sign-on')
  declare singleSignOn: AsyncBelongsTo<SingleSignOnModel>;

  @belongsTo('sms-setting') declare smsSetting: AsyncBelongsTo<SmsSettingModel>;
  @belongsTo('terms-of-service-setting')
  declare termsOfServiceSetting: AsyncBelongsTo<TermsOfServiceSettingModel>;

  @belongsTo('user', { inverse: null })
  declare cookieContactUser: AsyncBelongsTo<UserModel>;

  @belongsTo('user', { inverse: null })
  declare manager: AsyncBelongsTo<UserModel>;

  @belongsTo('wallet') declare wallet: AsyncBelongsTo<WalletModel>;

  @hasMany('avatar')
  declare avatars: AsyncHasMany<AvatarModel>;

  @hasMany('auto-join-domain')
  declare autoJoinDomains: AsyncHasMany<AutoJoinDomainModel>;

  @hasMany('batch-job') declare batchJobs: AsyncHasMany<BatchJobModel>;
  @hasMany('canned-response')
  declare cannedResponses: AsyncHasMany<CannedResponseModel>;

  @hasMany('canned-response', { inverse: 'dataPrivacyCompany' })
  declare dataPrivacyCannedResponses: AsyncHasMany<CannedResponseModel>;

  @hasMany('career-site') declare careerSites: AsyncHasMany<CareerSiteModel>;
  @hasMany('channel-activation')
  declare channelActivations: AsyncHasMany<ChannelActivationModel>;

  @hasMany('copilot-setting')
  declare copilotSettings: AsyncHasMany<CopilotSettingModel>;

  @hasMany('custom-field', { polymorphic: true })
  declare customFields: AsyncHasMany<CustomFieldModel>;

  @hasMany('dashboard-widget')
  declare dashboardWidgets: AsyncHasMany<DashboardWidgetModel>;

  @hasMany('default-notification-config')
  declare defaultNotificationConfigs: AsyncHasMany<DefaultNotificationConfigModel>;

  @hasMany('department') declare departments: AsyncHasMany<DepartmentModel>;
  @hasMany('division') declare divisions: AsyncHasMany<DivisionModel>;
  @hasMany('feature', { async: false })
  declare features: SyncHasMany<FeatureModel>;

  @hasMany('group-site-company')
  declare groupSiteCompanies: AsyncHasMany<GroupSiteCompanyModel>;

  @hasMany('image') declare images: AsyncHasMany<ImageModel>;
  @hasMany('interview-kit')
  declare interviewKits: AsyncHasMany<InterviewKitModel>;

  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;
  @hasMany('job', { inverse: 'templateCompany' })
  declare templates: AsyncHasMany<JobModel>;

  @hasMany('location') declare locations: AsyncHasMany<LocationModel>;
  @hasMany('meeting-room') declare meetingRooms: AsyncHasMany<MeetingRoomModel>;
  @hasMany('nps-response') declare npsResponses: AsyncHasMany<NpsResponseModel>;
  @hasMany('page') declare pages: AsyncHasMany<PageModel>;
  @hasMany('partner-activation')
  declare partnerActivations: AsyncHasMany<PartnerActivationModel>;

  @hasMany('picked-dashboard-widget', { async: false })
  declare pickedDashboardWidgets: SyncHasMany<PickedDashboardWidgetModel>;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('question') declare questions: AsyncHasMany<QuestionModel>;
  @hasMany('recruiting-firm')
  declare recruitingFirms: AsyncHasMany<RecruitingFirmModel>;

  @hasMany('reference-template')
  declare referenceTemplates: AsyncHasMany<ReferenceTemplateModel>;

  @hasMany('region') declare regions: AsyncHasMany<RegionModel>;
  @hasMany('requisition') declare requisitions: AsyncHasMany<RequisitionModel>;
  @hasMany('requisition-archive-reason')
  declare requisitionArchiveReasons: AsyncHasMany<RequisitionArchiveReasonModel>;

  @hasMany('requisition-flow')
  declare requisitionFlows: AsyncHasMany<RequisitionFlowModel>;

  @hasMany('role') declare roles: AsyncHasMany<RoleModel>;
  @hasMany('sandbox-activation')
  declare sandboxActivations: AsyncHasMany<SandboxActivationModel>;

  @hasMany('scorecard-criterium')
  declare scorecardCriteria: AsyncHasMany<ScorecardCriteriumModel>;

  @hasMany('section', { polymorphic: true })
  declare _sections: AsyncHasMany<SectionModel>;

  @hasMany('slack-app') declare slackApps: AsyncHasMany<SlackAppModel>;
  @hasMany('source') declare sources: AsyncHasMany<SourceModel>;
  @hasMany('stage-name') declare stageNames: AsyncHasMany<StageNameModel>;
  @hasMany('stage-type') declare stageTypes: AsyncHasMany<StageTypeModel>;
  @hasMany('subscription-contract')
  declare subscriptionContracts: AsyncHasMany<SubscriptionContractModel>;

  @hasMany('subscription-invoice')
  declare subscriptionInvoices: AsyncHasMany<SubscriptionInvoiceModel>;

  @hasMany('survey') declare surveys: AsyncHasMany<SurveyModel>;
  @hasMany('team') declare teams: AsyncHasMany<TeamModel>;
  @hasMany('user', { inverse: null })
  declare allAdminsAndRecruitmentAdmins: AsyncHasMany<UserModel>;

  @hasMany('user', { inverse: null }) declare users: AsyncHasMany<UserModel>;

  @hasMany('user-template')
  declare userTemplates: AsyncHasMany<UserTemplateModel>;

  @hasMany('webhook-subscription')
  declare webhooks: AsyncHasMany<WebhookSubscriptionModel>;

  @attr('array') declare groupAnalyticsChildCompanyIds: Array<number>;

  @attr('boolean') declare biConnectorActive: boolean;
  @attr('boolean') declare blockSuperadminAccess: boolean;
  @attr('boolean') declare canDisableSharingImages: boolean;
  @attr('boolean') declare completedCareerSite: boolean;
  @attr('boolean') declare currentLiveCustomer: boolean;
  @attr('boolean') declare customInvoiceAddress: boolean;
  @attr('boolean') declare customer: boolean;
  @attr('boolean') declare employeeDashboardRecruitmentProgress: boolean;
  @attr('boolean') declare activatedApprovals: boolean;
  @attr('boolean') declare automaticCandidateDataRequests: boolean;
  @attr('boolean') declare groupSite: boolean;
  @attr('boolean') declare hasAnyCronofyServiceAccountAuthorization: boolean;
  @attr('boolean') declare hasAnyCronofyBusinessConnectAuthorization: boolean;
  @attr('boolean') declare hasApprovals: boolean;
  @attr('boolean') declare hasEnabledGroupAnalytics: boolean;
  @attr('boolean') declare hasInternalJobs: boolean;
  @attr('boolean') declare hasInterviewKits: boolean;
  @attr('boolean') declare hasParentCompany: boolean;
  @attr('boolean') declare hasSingleSignOnFeature: boolean;
  @attr('boolean') declare instagramExpired: boolean;
  @attr('boolean') declare isFieldEmploymentLevelEnabled: boolean;
  @attr('boolean') declare isFieldEmploymentTypeEnabled: boolean;
  @attr('boolean') declare isFieldRemoteStatusEnabled: boolean;
  @attr('boolean') declare isFieldSalaryEnabled: boolean;
  @attr('boolean') declare isLocked: boolean;
  @attr('boolean') declare jobOfferApprovalsEnabled: boolean;
  @attr('boolean') declare sandbox: boolean;
  @attr('boolean') declare showCookieConsent: boolean;
  @attr('boolean') declare singleSignOnActive: boolean;
  @attr('boolean') declare twoFactorAuthenticationEnforced: boolean;
  @attr('boolean') declare yearlySummaryCustomColor: boolean;
  @attr('boolean') declare yearlySummaryNpsComments: boolean;
  @attr('boolean') declare yearlySummaryNpsScore: boolean;
  @attr('boolean') declare yearlySummaryReferral: boolean;
  @attr('boolean', { defaultValue: false }) declare applyWithLinkedin: boolean;
  @attr('boolean', { defaultValue: false })
  declare applyWithLinkedinIntegration: boolean;

  @attr('boolean', { defaultValue: false }) declare applyWithSeek: boolean;
  @attr('boolean', { defaultValue: false })
  declare enterpriseCalendarEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare isCustomStylesEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare linkedinRscIntegration: boolean;

  @attr('boolean', { defaultValue: false })
  declare parentHasActiveSingleSignOn: boolean;

  @attr('boolean', { defaultValue: false })
  declare parentHasEnterpriseCalendar: boolean;

  @attr('boolean', { defaultValue: false }) declare paymentByInvoice: boolean;
  @attr('boolean', { defaultValue: false }) declare restrictTagChanges: boolean;
  @attr('boolean', { defaultValue: false })
  declare validCreditCardPayment: boolean;

  @attr('boolean', { defaultValue: true }) declare applyWithFacebook: boolean;
  @attr('boolean', { defaultValue: true })
  declare applyWithLinkedinBasic: boolean;

  @attr('date') declare churnedAt: Date | null;
  @attr('date') declare createdAt: Date | null;
  @attr('date') declare trialEndsAt: Date | null;
  @attr('date') declare twoFactorAuthenticationEnforcedAt: Date | null;
  @attr('date') declare went_live_at: Date | null;

  @attr('number') declare industryId: number;
  @attr('number') declare shareLinkExpireDays: number;
  @attr('number') declare tagFormat: number;
  @attr('number') declare templateId: number;

  @attr declare aboardId: string | null;

  @attr('raw') declare paths: Raw;
  @attr('raw') declare pusherChannels: {
    candidates: string;
    main: string;
    dashboard: string;
    candidateChats: string;
  };

  @attr('raw') declare ratingDescriptions: Array<string>;
  @attr('raw', { defaultValue: () => [] })
  declare availableCareerSiteLanguages: Raw;

  @attr('string') declare applyButtonTextDefault: string;
  @attr('string') declare color: string;
  @attr('string') declare customCookiePolicy: string;
  @attr('string') declare demoDataStatus: string;
  @attr('string') declare departmentHeadlineDefault: string;
  @attr('string') declare enterpriseCalendarContact: string;
  @attr('string') declare facebookPage: string;
  @attr('string') declare facebookPageId: string | null;
  @attr('string') declare facebookTrackingPixel: string;
  @attr('string') declare googleTagId: string;
  @attr('string') declare gtmContainerId: string;
  @attr('string') declare instagram: string;
  @attr('string') declare intercomId: string;
  @attr('string') declare linkedinClientId: string;
  @attr('string') declare localeAts: string;
  @attr('string') declare meetingVisibility: string;
  @attr('string') declare missingPaymentReason: string;
  @attr('string') declare name: string;
  @attr('string') declare optOutInformation: string;
  @attr('string') declare organizationNumber: string;
  @attr('string') declare replyToInformation: string;
  @attr('string') declare seekAdvertiserId: string;
  @attr('string') declare slackBotTeamName: string;
  @attr('string') declare slackBotToken: string;
  @attr('string') declare styleguideUrl: string;
  @attr('string') declare subdomain: string;
  @attr('string') declare subdomainUrl: string;
  @attr('string') declare timeFormat: string;
  @attr('string') declare timeZone: string;
  @attr('string') declare timeZoneIdentifier: string;
  @attr('string') declare uuid: string;
  @attr('string') declare video: string;
  @attr('string') declare yearlySummary: string;
  @attr('string') declare yearlySummaryColor: string;
  @attr('string') declare yearlySummaryUrl: string;

  get admins() {
    return get(this, 'sortedUsers').filter((u) => u.admin);
  }

  get availableJobTemplates() {
    return get(this, 'templates').filter((t) => !t.defaultTemplate);
  }

  get batchJobsWithoutSubJobs() {
    return get(this, 'batchJobs').filter((b) => !b.isSubJob);
  }

  get externalCustomFields() {
    return get(this, 'customFields').filter((c) => c.isExternal);
  }

  get filterableCustomFields() {
    return get(this, 'externalCustomFields').filter((e) => e.isFilterable);
  }

  get hasApprovalSetting() {
    return !isEmpty(this.approvalSetting);
  }

  get hasPartnerActivationsWithManual() {
    return !isEmpty(this.partnerActivationsWithManual);
  }

  get hasPartnerActivationsWithResults() {
    return !isEmpty(this.partnerActivationsWithResults);
  }

  get hasSmsSetting() {
    return !!get(this.smsSetting, 'id');
  }

  get installedPartnerActivations() {
    return this.webhookPartnerActivations.filterBy('installed', true);
  }

  get locationsWithoutRegion() {
    return get(this, 'locations').filter((l) => l.withoutRegion);
  }

  get notArchivedJobs() {
    return get(this, 'jobs').filter((j) => j.notArchived);
  }

  get partnerActivationsWithManual() {
    return get(this, 'partnerActivations').filter(
      (p) => p.capabilityCandidateManual
    );
  }

  get partnerActivationsWithResults() {
    return get(this, 'partnerActivations').filter(
      (p) => p.capabilityCandidateResults
    );
  }

  get publishedCareerSites() {
    return get(this, 'careerSites').filter((c) => c.isPublished);
  }

  get publishedJobs() {
    return get(this, 'jobs').filter((j) => j.isPublished);
  }

  get regionsWithLocations() {
    return this.regions.filterBy('withLocations');
  }

  get searchableCandidateCustomFields() {
    return get(this, 'visibleCandidateCustomFields').filterBy(
      'isSearchable',
      true
    );
  }

  get searchableJobCustomFields() {
    return get(this, 'visibleJobCustomFields').filterBy('isSearchable', true);
  }

  get sections() {
    return this._sections.filterBy('pageId', undefined); // NOTE: This looks weird. Shouldn't we filter on not undefined?
  }

  get sortedLocations() {
    return get(this, 'locations')
      .slice()
      .sort((a, b) => a.nameOrCity.localeCompare(b.nameOrCity, 'sv'));
  }

  get sortedRegions() {
    return this.regions
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'));
  }

  get sortedRequisitionArchiveReasons() {
    return get(this, 'requisitionArchiveReasons')
      .slice()
      .sort((a, b) => a.reason.localeCompare(b.reason));
  }

  get sortedRegionsWithLocations() {
    return this.regionsWithLocations
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'));
  }

  get sortedLocationsByRegions() {
    const regions = [];
    if (this.sortedRegionsWithLocations.length) {
      for (const region of this.sortedRegionsWithLocations) {
        const regionGroup = {
          groupName: region.name,
          options: region.locations.slice(),
        };

        regions.push(regionGroup);
      }
    }

    const sortedLocationsWithoutRegion =
      this.locationsWithoutRegion.sortBy('nameOrCity');

    const groupOtherLocations = regions.length
      ? [
          {
            groupName: this.intl.t('common.other_locations'),
            options: sortedLocationsWithoutRegion,
          },
        ]
      : sortedLocationsWithoutRegion.slice();

    return [
      ...regions,
      ...(sortedLocationsWithoutRegion.length ? groupOtherLocations : []),
    ];
  }

  get orderedStageTypes() {
    return this.stageTypes.slice().sort((a, b) => a.orderIndex - b.orderIndex);
  }

  get editableStageTypes() {
    return this.stageTypes
      .slice()
      .filter(
        (stageType) =>
          !UNEDITABLE_STAGE_TYPES.includes(stageType.category ?? '')
      )
      .sort((a, b) => a.orderIndex - b.orderIndex);
  }

  get hasNpsResponse() {
    return !isEmpty(this.npsResponses);
  }

  get cname() {
    return get(this.domain, 'cname');
  }

  get previewUrl() {
    return this.subdomainUrl;
  }

  get sortedDivisions() {
    return get(this, 'divisions')
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get sortedDepartments() {
    return get(this, 'departments')
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get sortedUsers() {
    return this.users.sortBy('nameOrEmail');
  }

  get candidatesChannel() {
    return this.pusherChannels.candidates;
  }

  get candidateChatsChannel() {
    return this.pusherChannels.candidateChats;
  }

  get mainChannel() {
    return this.pusherChannels.main;
  }

  get sortedCannedResponses() {
    return this.cannedResponses.sortBy('name');
  }

  get divisionsWithoutNoDivision() {
    return this.divisions.filter((division) => division.id !== NO_DIVISION_ID);
  }

  get departmentsWithoutNoDepartment() {
    return this.sortedDepartments.rejectBy('id', NO_DEPARTMENT_ID);
  }

  get defaultReplyMessage() {
    return get(this, 'cannedResponses').find((c) => c.defaultReply);
  }

  get defaultRejectMessage() {
    return get(this, 'cannedResponses').find((c) => c.reject);
  }

  get sectionsByName() {
    return this.sections.slice().reduce((sectionsByName, section) => {
      // @ts-expect-error dunno about the result honestly
      sectionsByName[section.name] = section;
      return sectionsByName;
    }, {});
  }

  get defaultCareerSite() {
    return get(this, 'careerSites').findBy('isDefault', true);
  }

  get defaultJobTemplate() {
    return this.templates.findBy('defaultTemplate', true);
  }

  get canCreateRequisition() {
    return (
      get(get(this, 'requisitionFlows'), 'isFulfilled') &&
      get(this, 'requisitionFlows').isAny('isDefault', true)
    );
  }

  _candidateCount = trackedFunction(this, async () => {
    const { count } = await this.fetchCandidateCount();
    return count;
  });

  get candidateCount() {
    return this._candidateCount.value;
  }

  get hasRTLCareersite() {
    const careerSiteLanguageCodes = this.careerSites.map(
      (careerSite) => careerSite.languageCode
    );

    return rtlLanguageCodes.some((languageCode) =>
      careerSiteLanguageCodes.includes(languageCode)
    );
  }

  get visibleCandidateCustomFields() {
    return get(this, 'visibleCustomFields').filterBy('ownerType', 'Candidate');
  }

  get visibleCustomFields() {
    return get(this, 'customFields').filterBy('isHidden', false);
  }

  get visibleJobCustomFields() {
    return get(this, 'visibleCustomFields').filterBy('ownerType', 'Job');
  }

  get visibleUsers() {
    return this.sortedUsers.filterBy('visible', true);
  }

  get webhookPartnerActivations() {
    return this.partnerActivations.filterBy('webhook');
  }

  get creditCardForPromote() {
    const flipperEnabled = get(
      this.flipper,
      'purchase_promotion_by_credit_card'
    );
    return flipperEnabled || this.validCreditCardPayment;
  }

  get missingCreditCardForPromote() {
    const flipperEnabled = get(
      this.flipper,
      'purchase_promotion_by_credit_card'
    );
    return flipperEnabled && !this.validCreditCardPayment;
  }

  fetchGroupedCannedResponsesByContext = (context: {
    department?: string | null;
    location?: string | null;
    region?: string | null;
    role?: string | null;
  }) => {
    const all = get(this, 'sortedCannedResponses');
    return fetchGroupedAssetsByContext(all, context, this.intl);
  };

  hasFeature = (feature: FeatureModel | AddonFeature) => {
    const featureName =
      typeof feature === 'string' ? feature : get(feature, 'internalName');

    switch (featureName) {
      case 'linkedin_rsc':
        return this.linkedinRscIntegration;
      case 'bi_connector':
        return this.biConnectorActive;
      case 'custom_reject':
        return true;
      case 'facebook':
        return this.facebookPageId !== null;
      case 'sms':
        return this.hasSmsSetting;
      case 'sso':
        return this.hasSingleSignOnFeature;
      case 'two_factor_authentication':
        return this.twoFactorAuthenticationEnforced;
      case 'enterprise_calendar':
        return this.enterpriseCalendarEnabled;
      default:
        return this.features.mapBy('internalName').includes(featureName);
    }
  };

  hasCopilotFeature = (name: string) => {
    // Also update the Rails company model when changing this

    if (!this.hasFeature('recruiter_copilot')) {
      return false;
    }

    switch (name) {
      // This feature is behind flipper
      case 'suggest_existing_candidates': {
        if (!get(this.flipper, 'copilot_suggest_existing_candidates')) {
          return false;
        }

        break;
      }

      case 'video_meeting_summary': {
        if (!this.hasFeature('video_recording')) {
          return false;
        }

        break;
      }

      case 'interview_kit_question_answers': {
        if (
          !this.hasFeature('video_recording') ||
          !this.hasFeature('interview_kit')
        ) {
          return false;
        }

        break;
      }

      case 'suggest_skills_traits':
      case 'suggest_interview_kit_questions': {
        if (!this.hasFeature('interview_kit')) {
          return false;
        }

        break;
      }
    }

    const copilotSetting = this.copilotSettings.findBy('name', name);

    // All features except suggest_existing_candidates are enabled by default
    if (copilotSetting) {
      return copilotSetting.enabled;
    } else if (name === 'suggest_existing_candidates') {
      return false;
    } else {
      return true;
    }
  };

  async activateYearlySummary() {
    return this.server.memberAction(this, {
      action: 'activate_yearly_summary',
    });
  }

  async createLinkedinPartner() {
    return this.server.memberAction(this, {
      action: 'create_linkedin_partner',
    });
  }

  async deactivateYearlySummary() {
    return this.server.memberAction(this, {
      action: 'deactivate_yearly_summary',
    });
  }

  async enableCustomStyles(data: object) {
    return this.server.memberAction(this, {
      action: 'enable_custom_styles',
      options: { data },
    });
  }

  async fetchCandidateCount(): Promise<{ count: number }> {
    return this.server.memberAction(this, {
      action: 'candidate_count',
      method: 'GET',
    });
  }

  async getTranslation(data: Record<string, string>) {
    return this.server.memberAction(this, {
      action: 'translation',
      method: 'GET',
      queryParams: data,
    });
  }

  async integrateLinkedinPartner(data: object) {
    return this.server.memberAction(this, {
      action: 'integrate_linkedin_partner',
      options: { data },
    });
  }

  async validateSeatholderCall() {
    return this.server.memberAction(this, {
      action: 'validate_seatholder_call',
      method: 'GET',
    });
  }

  async prepareYearlySummary() {
    return this.server.memberAction(this, { action: 'prepare_yearly_summary' });
  }

  async removeInstagram() {
    return this.server.memberAction(this, { action: 'remove_instagram' });
  }

  async retryEnterpriseCalendarConnections() {
    return this.server.memberAction(this, {
      action: 'retry_enterprise_calendar_connections',
    });
  }

  async saveSocialMedia() {
    const { facebook_page } = this.serialize() as { facebook_page: string };

    const response = await this.server.memberAction<typeof this.serialize>(
      this,
      {
        action: 'update_social_media',
        options: {
          data: JSON.stringify({ company: { facebook_page } }),
        },
      }
    );

    return this.store.pushPayload('company', response);
  }

  async saveTagSettings() {
    const { tag_format, restrict_tag_changes } = this.serialize() as {
      tag_format: Date;
      restrict_tag_changes: boolean;
    };

    const response = await this.server.memberAction<typeof this.serialize>(
      this,
      {
        action: 'update_tag_settings',
        options: {
          data: JSON.stringify({
            company: { tag_format, restrict_tag_changes },
          }),
        },
      }
    );

    this.store.pushPayload('company', response);
  }

  async toggleYearlySummaryOption(attribute: unknown) {
    return this.server.memberAction(this, {
      action: 'toggle_yearly_summary_option',
      options: {
        data: JSON.stringify(attribute),
      },
    });
  }

  async updateYearlySummaryColor(attributes: unknown) {
    return this.server.memberAction(this, {
      action: 'update_yearly_summary_color',
      options: {
        data: JSON.stringify(attributes),
      },
    });
  }

  async updateYearlySummaryNpsComments(attributes: unknown) {
    return this.server.memberAction(this, {
      action: 'update_yearly_summary_nps_comments',
      options: {
        data: JSON.stringify(attributes),
      },
    });
  }

  async yearlySummaryNpsData() {
    return this.server.memberAction(this, {
      action: 'yearly_summary_nps_data',
      method: 'GET',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    company: CompanyModel;
  }
}
