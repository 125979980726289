/* import __COLOCATED_TEMPLATE__ from './info.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import UserModel from 'teamtailor/models/user';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import FlipperService from 'teamtailor/services/flipper';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import { get } from 'teamtailor/utils/get';

type EmployeesProfileInfoArgs = {
  employee: UserModel;
};

export default class EmployeesProfileInfo extends Component<EmployeesProfileInfoArgs> {
  @service declare current: CurrentService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  get user(): UserModel {
    return this.current.user;
  }

  get employee(): UserModel {
    return this.args.employee;
  }

  get userStatusText(): SafeString {
    const activeStatus = get(this.employee, 'activeStatus');
    const statusText = get(activeStatus, 'statusText') || '';
    return htmlSafe(statusText);
  }

  @action
  handleImageUploaded(pictureUrl: string): Promise<UserModel> {
    this.employee.pictureCache = pictureUrl;
    return this.employee.save();
  }

  @action
  async handleImageRemoved(): Promise<void> {
    this.employee.picture = null;
    await this.employee.save();
    this.flashMessages.success(this.intl.t('employees.profile.image_removed'));
  }
}
