/* import __COLOCATED_TEMPLATE__ from './employee-row.hbs'; */
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class EmployeeRow extends Component {
  @service intl;
  @service current;
  @service flipper;

  get joinedText() {
    if (get(this, 'employee.hasLogin')) {
      return this.intl.t('components.employee_row.joined');
    } else if (get(this, 'employee.hasUserInvitation')) {
      return this.intl.t('components.employee_row.invited');
    } else {
      return this.intl.t('components.employee_row.not_invited');
    }
  }

  get visibilityText() {
    if (get(this, 'employee.visible')) {
      return this.intl.t('employees.visible');
    } else {
      return this.intl.t('employees.hidden');
    }
  }

  get twoFactorAuthenticationText() {
    if (get(this, 'employee.login.twoFactorActivated')) {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_active.title'
      );
    } else {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_disabled.title'
      );
    }
  }

  get singleSignOnText() {
    if (get(this, 'employee.login.ssoLogin')) {
      return this.intl.t(
        'components.employee_row.single_sign_on_is_active.title'
      );
    } else {
      return this.intl.t(
        'components.employee_row.single_sign_on_is_disabled.title'
      );
    }
  }

  get joinedTooltip() {
    if (get(this, 'employee.hasLogin')) {
      return this.intl.t('components.employee_row.accepted_invitation');
    } else if (get(this, 'employee.hasUserInvitation')) {
      return this.intl.t('components.employee_row.invited_not_joined');
    }
  }

  get visibilityTooltip() {
    if (get(this, 'employee.visible')) {
      return this.intl.t('components.employee_row.profile_is_public');
    } else {
      return this.intl.t('components.employee_row.profile_is_private');
    }
  }

  get twoFactorAuthenticationTooltip() {
    if (get(this, 'employee.login.twoFactorActivated')) {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_active.description'
      );
    } else {
      return this.intl.t(
        'components.employee_row.two_factor_authentication_is_disabled.description'
      );
    }
  }

  get SingleSignOnTooltip() {
    if (get(this, 'employee.login.ssoLogin')) {
      return this.intl.t(
        'components.employee_row.single_sign_on_is_active.description'
      );
    } else {
      return this.intl.t(
        'components.employee_row.single_sign_on_is_disabled.description'
      );
    }
  }

  get isEmployeeSelected() {
    const selectedIds = this.args.selectedIds ?? [];
    const deselectedIds = this.args.deselectedIds ?? [];
    if (this.args.selectAll) {
      return !deselectedIds.includes(this.args.employee.id);
    }
    return selectedIds.includes(this.args.employee.id);
  }

  @action
  toggleRowSelection(isSelected) {
    this.args.onSelectionChange({
      employeeId: this.args.employee.id,
      isSelected,
    });
  }
}
