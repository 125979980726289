/* import __COLOCATED_TEMPLATE__ from './add-form.hbs'; */
import Store from '@ember-data/store';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';
import {
  allSettled,
  Settled,
  task,
  TaskGroup,
  taskGroup,
  TaskInstance,
} from 'ember-concurrency';
import FileQueue from 'ember-file-upload/services/file-queue';
import DataTransferWrapper from 'ember-file-upload/system/data-transfer-wrapper';
import { UploadFile } from 'ember-file-upload';
import DepartmentModel from 'teamtailor/models/department';
import JobModel from 'teamtailor/models/job';
import RoleModel from 'teamtailor/models/role';
import Current from 'teamtailor/services/current';
import DirectUploadService from 'teamtailor/services/direct-upload';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import PermissionsService from 'teamtailor/services/permissions';
import PusherService, { PusherChannel } from 'teamtailor/services/pusher';
import { QueueItemClass } from './queue-item';
import {
  IAttachment,
  ICandidateAttributes,
  IHrFlowResponse,
  IUrl,
} from './types';

import config from 'teamtailor/config/environment';
import CandidateModel from 'teamtailor/models/candidate';
import { get } from 'teamtailor/utils/get';
import { DivisionModel } from 'teamtailor/models';

interface Args {
  onClose(arg?: string): void;
  division?: DivisionModel;
  department?: DepartmentModel;
  role?: RoleModel;
  job?: JobModel;
  queueItems: QueueItemClass[];
}

const QUEUE_ITEM_LIMIT = 100;

const findLinkedInUrl = (urls: IUrl[]): string | null => {
  const linkedInUrl = urls.find((url) => url.type === 'linkedin');
  return linkedInUrl ? linkedInUrl.url : null;
};

const findResumeUrl = (attachments: IAttachment[]): string => {
  const removeUrl = attachments.find(
    (attachment) => attachment.type === 'resume'
  );
  return removeUrl ? removeUrl.public_url : '';
};

const parseResponse = (response: IHrFlowResponse): ICandidateAttributes => {
  return {
    email: response.data.profile.info.email,
    firstName: response.data.profile.info.first_name,
    lastName: response.data.profile.info.last_name,
    phone: response.data.profile.info.phone,
    linkedinUrl: findLinkedInUrl(response.data.profile.info.urls),
    remotePictureUrl: response.data.profile.info.picture,
    remoteResumeUrl: findResumeUrl(response.data.profile.attachments),
  };
};

export default class AddForm extends Component<Args> {
  @service declare store: Store;
  @service declare current: Current;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare permissions: PermissionsService;
  @service declare directUpload: DirectUploadService;
  @service declare pusher: PusherService;
  @service declare router: RouterService;
  @service declare fileQueue: FileQueue;

  @tracked declare editingQueueItem?: QueueItemClass;
  @tracked declare pickedJob?: JobModel;

  @taskGroup declare parse: TaskGroup<unknown>;
  @taskGroup declare save: TaskGroup<unknown>;

  @tracked filesAboveThePreview = 0;

  get lastParseError(): unknown {
    return this.parse.lastErrored?.error;
  }

  readonly fileExtMimeMap = {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    rtf: ['application/rtf', 'application/x-rtf', 'text/richtext'],
    txt: 'text/plain',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ppt: [
      'application/mspowerpoint',
      'application/powerpoint',
      'application/vnd.ms-powerpoint',
      'application/x-mspowerpoint',
    ],

    odt: 'application/vnd.oasis.opendocument.text',
    pages: 'application/x-iwork-pages-sffpages',
  };

  readonly fileUploadSingle = 'fileUploadSingle';
  readonly fileUploadMulti = 'fileUploadMulti';
  readonly noConvertFileTypes = ['pdf'];
  readonly allowedFileExtensions = Object.keys(this.fileExtMimeMap).join(', ');
  readonly acceptedMimes = Object.values(this.fileExtMimeMap).flatMap((v) => v);
  readonly accept = this.acceptedMimes.join(',');

  private declare channelName: string;
  private declare pusherChannel?: PusherChannel;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.pickedJob = this.args.job;
  }

  willDestroy() {
    super.willDestroy();
    this.pusherChannel?.unsubscribe();
    this.parse.cancelAll();
    this.save.cancelAll();
    this.args.queueItems.clear();
  }

  private beforeUpload = (files: UploadFile[]) => {
    if (this.queueItems.length + files.length >= QUEUE_ITEM_LIMIT) {
      this.flashMessages.error(
        this.intl.t(
          'candidates.segment.new_candidate_bulk.add_form.upload_limit',
          { limit: QUEUE_ITEM_LIMIT }
        )
      );
    }
  };

  private createQueueItem = () => {
    const { division, department, role } = this.args;
    return new QueueItemClass(division, department, role);
  };

  private removeNonDirtyQueueItems() {
    this.queueItems.removeObjects(this.queueItems.filterBy('isDirty', false));
  }

  get queueItems() {
    return this.args.queueItems;
  }

  get isUploadDisabled() {
    return this.queueItems.length === QUEUE_ITEM_LIMIT;
  }

  get formDisabled() {
    return this.editingQueueItem?.isProcessing ?? false;
  }

  get buttonText() {
    if (this.queueItems.isAny('isPersisted', true)) {
      return this.intl.t('candidates.candidate.add_candidate.save_changes');
    } else {
      return this.intl.t('candidates.candidate.add_candidate.add_candidates');
    }
  }

  get buttonDisabled() {
    return this.save.isRunning || this.parse.isRunning;
  }

  get queueItemsCount() {
    return this.queueItems.length;
  }

  get nonDirtyQueueItems() {
    return this.queueItems.filterBy('isDirty', false);
  }

  /**
   * Upload the file to HrFlow and store the parsed result in a queueItem
   */
  startUploadAndParseJob = task(
    { maxConcurrency: 3, enqueue: true, group: 'parse' },
    async (file: UploadFile, queueItem: QueueItemClass) => {
      const task = this.enqueueParseJob.perform(file, queueItem);
      queueItem.parseInstances.pushObject(task);
      const response = await task;

      const json = await response.json();

      const {
        email,
        firstName,
        lastName,
        phone,
        linkedinUrl,
        remotePictureUrl,
        remoteResumeUrl,
      } = parseResponse(json as IHrFlowResponse);

      queueItem.email = email;
      queueItem.firstName = firstName;
      queueItem.lastName = lastName;
      queueItem.phone = phone;
      queueItem.linkedinUrl = linkedinUrl;
      queueItem.remotePictureUrl = remotePictureUrl;
      queueItem.remoteResumeUrl = remoteResumeUrl;
      queueItem.hrFlowUploadFileReference = undefined; // new file is returned
    }
  );

  /**
   * Upload the file to HrFlow
   */
  enqueueParseJob = task(
    { group: 'parse' },
    async (file: UploadFile, queueItem: QueueItemClass) => {
      try {
        const hrflowResponse = await file.upload(
          `${config.hrflow.apiUrl}/v1/profile/parsing/file`,
          {
            headers: {
              'X-USER-EMAIL': config.hrflow.accountEmail,
              'X-API-KEY': config.hrflow.xApiKey,
            },

            fileKey: 'file',
            data: {
              source_key: config.hrflow.sourceKey,
              reference: queueItem.uuid,
              sync_parsing: '1',
            },
          }
        );

        return hrflowResponse;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        let message = this.intl.t(
          'candidates.segment.new_candidate_bulk.add_form.parsing_fail'
        );

        if (err.status && err.status >= 500) {
          message = this.intl.t(
            'candidates.segment.new_candidate_bulk.add_form.parsing_fail_50x'
          );
        }

        this.flashMessages.error(message);
        Sentry.captureException(err);
        throw new Error(message);
      }
    }
  );

  /**
   * Saves the candidate to our database
   */
  commitCandidate = task(
    { maxConcurrency: 3, enqueue: true, group: 'save' },
    async (queueItem: QueueItemClass) => {
      const saveTask = this.saveCandidate.perform(queueItem);
      queueItem.saveInstances.pushObject(saveTask);
      const candidateId = await saveTask;
      queueItem.candidateId = candidateId;
    }
  );

  /**
   * Retry either parse cv or save candidate.
   * The hrFlowUploadFileReference is removed from queueItem after parse success
   */
  retryTask = task(async (queueItem: QueueItemClass) => {
    queueItem.clearTasks();
    const file = queueItem.hrFlowUploadFileReference;
    if (file) {
      await this.startUploadAndParseJob.perform(file, queueItem);
    } else {
      await this.commitCandidate.perform(queueItem);
    }
  });

  /**
   * Generator used when dropping a file DropZone
   */
  handleFileDrop = task(async (file: UploadFile) => {
    this.removeNonDirtyQueueItems();
    if (
      this.queueItems.length >= QUEUE_ITEM_LIMIT &&
      file.queue?.name === this.fileUploadMulti
    ) {
      this.fileQueue.find(this.fileUploadMulti)?.remove(file);
    } else {
      const queueItem = this.createQueueItem();
      queueItem.hrFlowUploadFileReference = file;
      this.queueItems.pushObject(queueItem);

      if (
        !this.queueItems.find(
          (queuedItem) => queuedItem === this.editingQueueItem
        )
      ) {
        this.editingQueueItem = queueItem;
      }

      await this.startUploadAndParseJob.perform(file, queueItem);
    }
  });

  saveCandidate = task({ group: 'save' }, async (queueItem: QueueItemClass) => {
    try {
      const jobId = this.pickedJob?.id;
      queueItem.model = this.store.createRecord('candidate', {
        firstName: queueItem.firstName,
        lastName: queueItem.lastName,
        email: queueItem.email,
        phone: queueItem.phone,
        linkedinUrl: queueItem.linkedinUrl,
        remotePictureUrl: queueItem.remotePictureUrl, // picture from parse
        pictureCache: queueItem.picture, // picture uploaded by user
        resumeRemoteUrl: queueItem.remoteResumeUrl,
        resume: queueItem.remoteResumeUrl,
        jobId,
        division: queueItem.division,
        department: queueItem.department,
        role: queueItem.role,
        tags: queueItem.tags,
        note: queueItem.note,
        reviewRating: queueItem.reviewRating,
        isFromBulkAdd: true,
        pickedLocations: queueItem.locations.map((location) => {
          return this.store.createRecord('picked-location', {
            location,
          });
        }),
      });

      const result: CandidateModel = await queueItem.model.save();
      return result.id;
    } catch (err) {
      const message = this.intl.t(
        'candidates.segment.new_candidate_bulk.add_form.error_saving_candidate'
      );

      this.flashMessages.error(message);
      throw err;
    }
  });

  /**
   * Save all queue items
   */
  saveCandidates = task(async () => {
    const taskInstances = this.queueItems
      .rejectBy('isPersisted', true)
      .map((queueItem) => {
        queueItem.clearTasks();
        const commitTask = this.commitCandidate.perform(queueItem);
        return commitTask;
      });

    const result: Settled<TaskInstance<void>>[] =
      await allSettled(taskInstances);

    if (result.isEvery('state', 'fulfilled')) {
      this.flashMessages.success(
        this.intl.t('candidates.candidate.add_candidate.candidates_saved')
      );

      const jobDetail = await get(this.pickedJob, 'jobDetail');
      if (jobDetail) {
        const inboxStage = get(jobDetail, 'stages').find(
          (stage) => stage.inbox
        );
        inboxStage?.refreshCounts();
        inboxStage?.fetchJobApplicationsTask.perform({ reload: true });
      }

      if (taskInstances.length === 1) {
        assert('this.queueItems[0] must exist', this.queueItems[0]);

        if (this.pickedJob) {
          this.router.transitionTo(
            'jobs.job.stages.index.candidate',
            this.pickedJob.id,
            this.queueItems[0].candidateId ?? ''
          );
        } else if (this.current.user.admin) {
          this.router.transitionTo(
            'candidates.segment.candidate.index',
            'all',
            this.queueItems[0].candidateId ?? ''
          );
        }
      }

      this.args.onClose();
    }
  });

  @action
  handleOnDragEnter(_files: UploadFile[], dataTransfer: DataTransferWrapper) {
    const { items } = dataTransfer;
    this.filesAboveThePreview = items.length;
  }

  @action
  handleOnDragLeave() {
    this.filesAboveThePreview = 0;
  }

  @action
  handlePickedJob(job: JobModel) {
    this.pickedJob = job;
  }

  @action
  handlePickDepartment(department: DepartmentModel) {
    if (this.editingQueueItem) {
      this.editingQueueItem.department = department;
    }
  }

  @action
  handleAddCandidateModel() {
    const queueItem = this.createQueueItem();

    this.queueItems.pushObject(queueItem);
    this.handleSetEditingCandidate(queueItem);
  }

  @action
  handleSetEditingCandidate(queueItem: QueueItemClass) {
    this.editingQueueItem = queueItem;
  }

  @action
  handleDeleteQueueItem(queueItem: QueueItemClass) {
    queueItem.taskInstances?.forEach((t) => t.cancel());
    this.queueItems.removeObject(queueItem);

    if (this.queueItems.length === 0) {
      const newQueueItem = this.createQueueItem();
      this.queueItems.pushObject(newQueueItem);
      this.editingQueueItem = newQueueItem;
    }

    if (this.editingQueueItem === queueItem) {
      const editingQueueItem = this.queueItems.slice(-1)[0];

      assert('editingQueueItem must exist', editingQueueItem);

      this.editingQueueItem = editingQueueItem;
    }
  }

  @action
  handleFilter(file: UploadFile) {
    if (!this.acceptedMimes.includes(file.type)) {
      this.flashMessages.error(
        this.intl.t(
          'candidates.segment.new_candidate_bulk.add_form.file_not_supported',
          { filename: file.name }
        )
      );
      return false;
    }

    return true;
  }

  @action
  handleOnDrop(files: UploadFile[]) {
    this.removeNonDirtyQueueItems();
    this.beforeUpload(files);
  }

  @action
  initializeQueueItem() {
    const initialQueueItem = this.createQueueItem();
    this.queueItems.pushObject(initialQueueItem);
    this.editingQueueItem = initialQueueItem;
  }
}
