/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import { action, set, setProperties } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import BaseComponent, {
  BaseComponentArgs,
} from 'teamtailor/components/content-block/base-component';
import Store from '@ember-data/store';
import FlipperService from 'teamtailor/services/flipper';
import LocationModel from 'teamtailor/models/location';
import DivisionModel, { NO_DIVISION_ID } from 'teamtailor/models/division';
import DepartmentModel from 'teamtailor/models/department';
import FilthyContent from 'teamtailor/services/filthy-content';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

interface LanguageOption {
  languageCode: string;
  translatedLanguage: string;
}

type Languages = LanguageOption[];
type OptionType = {
  text: string;
  value: boolean;
};

export default class JobsListBaseComponent extends BaseComponent<BaseComponentArgs> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;
  @service declare filthyContent: FilthyContent;

  @sort('languageOptions', 'languageSorting')
  declare sortedLanguageOptions: Languages;

  declare isDepartmentPage: boolean;
  declare isLocationPage: boolean;

  @tracked declare selectedLocation: LocationModel | null;

  handleInitialSelectedLocation = modifier(async () => {
    const locationId = get(this.section, 'pickedLocationId');
    if (locationId) {
      this.selectedLocation = await this.store.findRecord(
        'location',
        locationId
      );
    } else {
      this.selectedLocation = null;
    }
  });

  get section() {
    return this.args.model;
  }

  get isHomePage() {
    return get(get(this.section, 'page'), 'isHomePage');
  }

  get isJobListPage() {
    return get(get(this.section, 'page'), 'isJobListPage');
  }

  get pageCareerSite() {
    return get(get(get(this.section, 'page'), 'pagePublication'), 'careerSite');
  }

  get languageSorting() {
    return Object.freeze(['isDefault:desc', 'translatedLanguage:asc']);
  }

  get languageOptions(): Languages {
    const languages = [
      {
        languageCode: 'all',
        translatedLanguage: this.intl.t('search.language.all_languages'),
      },
    ];

    languages.pushObjects(
      get(this.company, 'careerSites').map((careerSite: LanguageOption) => {
        const { languageCode, translatedLanguage } = careerSite;

        return {
          languageCode,
          translatedLanguage,
        };
      })
    );

    return languages;
  }

  get showFilterBy(): boolean {
    return (
      !this.isJobListPage && !this.isDepartmentPage && !this.isLocationPage
    );
  }

  get groupSiteOptions() {
    return [
      {
        text: this.intl.t(
          'content.editor.section.jobs_list.group_companies.this_company'
        ),

        value: false,
      },
      {
        text: this.intl.t(
          'content.editor.section.jobs_list.group_companies.all_companies'
        ),

        value: true,
      },
    ];
  }

  get sortedDepartments() {
    const pickedDivisionId = get(this.section, 'pickedDivisionId');

    if (this.company.hasFeature('divisions') && !pickedDivisionId) {
      // Can not filter by department unless division selected
      return [];
    }

    const filterDivisionId =
      pickedDivisionId == NO_DIVISION_ID ? null : pickedDivisionId;

    return this.company.sortedDepartments.filter((department) => {
      return get(department.division, 'id') == filterDivisionId;
    });
  }

  becomeDirty(): void {
    this.filthyContent.setFilthy(get(this.section, 'id'));
  }

  companyHasLocations() {
    return this.current.company.hasMany('locations').ids().length > 0;
  }

  @action
  becomeDirtyAction(): void {
    this.becomeDirty();
  }

  @action
  async handleSetLanguage(language: LanguageOption | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedLanguage', language?.languageCode);
    }
  }

  @action
  async handleSetDivision(division: DivisionModel | null) {
    const section = await this.section;
    if (section && get(section, 'pickedDivisionId') != division?.id) {
      setProperties(section, {
        pickedDivisionId: division?.id,
        pickedDepartmentId: undefined,
      });
    }
  }

  @action
  async handleSetDepartment(department: DepartmentModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedDepartmentId', department?.id);
    }
  }

  @action
  async handleSetLocation(location: LocationModel | null) {
    const section = await this.section;
    if (section) {
      set(section, 'pickedLocationId', location?.id);
    }
  }

  @action
  handleToggleInteractiveMap(): void {
    set(this.section, 'interactiveMap', !get(this.section, 'interactiveMap'));
  }

  @action
  setJobsListGroupCompanies(option: OptionType) {
    set(this.section, 'jobsListGroupCompanies', option.value);
  }
}
