/* import __COLOCATED_TEMPLATE__ from './location-form.hbs'; */
import TranslatableFormBaseComponent from 'teamtailor/components/translatable-form/base';
import { action, set } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import disableDefaultTemplate from 'teamtailor/utils/disable-default-template';
import Store from '@ember-data/store';
import LocationModel from 'teamtailor/models/location';
import { trackedFunction } from 'ember-resources/util/function';
import FlipperService from 'teamtailor/services/flipper';
import { modifier } from 'ember-modifier';

const REQUIRED_PROPS = ['name'];
export default class LocationFormComponent extends TranslatableFormBaseComponent<LocationModel> {
  @service declare store: Store;
  @service declare flipper: FlipperService;

  theme = 'default';
  translationsProps = REQUIRED_PROPS;

  @tracked showCityInput = false;

  get location() {
    return this.translatableModel as unknown as LocationModel;
  }

  get model() {
    return this.location;
  }

  region = trackedFunction(this, async () => {
    const region = await get(this.location, 'region');
    return region;
  });

  // Disable the headquarters checkbox if company has no or one location
  get hasCompanyLessThanOneLocation(): boolean {
    const numberOfLocations = +get(this.company, 'locations').length;
    return numberOfLocations < 2;
  }

  get isHqCheckboxDisabled(): boolean {
    return disableDefaultTemplate(this.model, 'headquarters');
  }

  get fullErrorMessages() {
    if (!this.showCityInput) {
      return get(this.location, 'fullErrorMessages').filter(
        (e: { attribute: string }) => !e.attribute.includes('city')
      );
    }

    return get(this.location, 'fullErrorMessages');
  }

  initHeadquartersCheckbox = modifier(() => {
    const hasNoCompanyHQ = this.company.locations.every(
      (location) => !location.headquarters
    );

    const hasCompanyLocations = get(this.company.locations, 'length');

    // If it exists for the company, one location has to be the headquarters as the default
    if (hasCompanyLocations && hasNoCompanyHQ) {
      this.handleToggleHeadquartersCheckbox(true);
    }
  });

  @action
  handleToggleHeadquartersCheckbox(value?: boolean): void {
    set(this.location, 'headquarters', Boolean(value));
  }

  @action
  checkCityPresence(): void {
    if (!this.location.city) set(this, 'showCityInput', true);
  }

  @action
  createRegion(name: string) {
    const region = this.store.createRecord('region', {
      name,
    });
    get(this.company, 'regions').pushObject(region);
    set(this.location, 'region', region);
    region.save();
  }
}
