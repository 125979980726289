/* import __COLOCATED_TEMPLATE__ from './team-card.hbs'; */
import Component from '@glimmer/component';
import { get } from 'teamtailor/utils/get';
import uniqBy from 'teamtailor/utils/uniq-by';

const ONE_ROW_MAX_CHARS = 45;

export default class TeamCardComponent extends Component {
  get team() {
    return this.args.team;
  }

  get rolesLimit() {
    let limit = 5;
    if (this.showDepartments) {
      limit -= 1;
    }

    if (this.showLocations) {
      limit -= 1;
    }

    return limit;
  }

  get rolesToShow() {
    const roles = get(this, 'team.roles').toArray();

    let limit = this.rolesLimit;
    if (limit + 1 === roles.length) {
      limit += 1; // display that extra role, instead of +1
    }

    if (roles.length > limit) {
      return roles.splice(0, limit);
    } else {
      return roles;
    }
  }

  get allUsers() {
    return uniqBy([...this.team.users.toArray(), this.team.user], 'id');
  }

  get showRoles() {
    return !!this.rolesToShow.length;
  }

  get notShownRolesCount() {
    return get(this.team, 'roles').length - this.rolesToShow.length;
  }

  get locationsToShow() {
    const locations = get(this.team, 'locations').toArray().mapBy('nameOrCity');
    return this.filterOneRowContent(locations);
  }

  get showLocations() {
    return !!this.locationsToShow.length;
  }

  get notShownLocationsCount() {
    return get(this.team, 'locations').length - this.locationsToShow.length;
  }

  get departmentsToShow() {
    const departments = get(this.team, 'departments').map((department) => {
      const division = get(department, 'division');
      const divisionName = get(division, 'name');
      return [divisionName, get(department, 'name')]
        .filter(Boolean)
        .join(' • ');
    });
    return this.filterOneRowContent(departments);
  }

  get showDepartments() {
    return !!this.departmentsToShow.length;
  }

  get notShownDepartmentsCount() {
    return get(this.team, 'departments').length - this.departmentsToShow.length;
  }

  get divisionsToShow() {
    const divisions = get(this.team, 'divisions').map((division) =>
      get(division, 'name')
    );
    return this.filterOneRowContent(divisions);
  }

  get showDivisions() {
    return !!this.divisionsToShow.length;
  }

  get notShownDivisionsCount() {
    return get(this.team, 'divisions').length - this.divisionsToShow.length;
  }

  filterOneRowContent(strings) {
    let length = 0;
    return strings.filter((string) => {
      if (string && length + string.length < ONE_ROW_MAX_CHARS) {
        length += string.length;
        return true;
      }

      return false;
    });
  }
}
