/* import __COLOCATED_TEMPLATE__ from './criteria-selection.hbs'; */
import Component from '@glimmer/component';
import { action, set, setProperties } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import RequisitionModel from 'teamtailor/models/requisition';
import uniqBy from 'teamtailor/utils/uniq-by';
import { NO_DIVISION_ID } from 'teamtailor/models/division';

export default class CriteriaSelection extends Component {
  get divisions() {
    return this.args.divisions.filter(
      (division) => division.id !== NO_DIVISION_ID
    );
  }

  get currentDepartment() {
    return get(this, 'args.model.department');
  }

  get currentDivision() {
    return get(this, 'args.model.division');
  }

  get filteredCountries() {
    const { locations } = this.args;
    return (
      locations &&
      uniqBy(
        locations.map((location) => {
          return { name: location.country };
        }),
        'name'
      )
    );
  }

  get filteredLocations() {
    const { locations } = this.args;
    const country = get(this, 'args.model.country');
    return locations && locations.filterBy('country', country);
  }

  get locationRequired() {
    return this.args.model instanceof RequisitionModel;
  }

  @action
  handleSetDivision(division) {
    setProperties(this.args.model, {
      division,
      department: null,
      role: null,
    });
  }

  @action
  handleSetDepartment(department) {
    setProperties(this.args.model, {
      department,
      role: null,
    });
  }

  @action
  handleSetLocation(location) {
    set(this.args.model, 'location', location);
  }

  @action
  handleSetRole(role) {
    set(this.args.model, 'role', role);
  }

  @action
  onSelectLocation(country) {
    const name = country?.name;
    if (get(this, 'args.model.country') !== name) {
      setProperties(this.args.model, {
        country: name || null,
        location: null,
      });
      this.args.onCriteriaChange?.('location');
    }
  }

  @action
  handleCriteriaChange() {
    this.args.onCriteriaChange?.();
  }
}
