/* import __COLOCATED_TEMPLATE__ from './modifier-grade.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface ModifierGradeArgs {
  modifierValue: number;
}

enum ModifierGrade {
  GOOD = 'good',
  FAIR = 'fair',
  POOR = 'poor',
  UNAVAILABLE = 'unavailable',
}

export default class ModifierGradeComponent extends Component<ModifierGradeArgs> {
  @service declare intl: IntlService;

  get modifierGrade() {
    if (this.args.modifierValue >= 7) {
      return ModifierGrade.GOOD;
    } else if (this.args.modifierValue >= 4) {
      return ModifierGrade.FAIR;
    } else if (this.args.modifierValue >= 0) {
      return ModifierGrade.POOR;
    } else {
      return ModifierGrade.UNAVAILABLE;
    }
  }

  get modifierText() {
    switch (this.modifierGrade) {
      case ModifierGrade.GOOD:
        return this.intl.t(
          'job.odds_of_hiring.hiring_forecast.modifier.grade_good'
        );
      case ModifierGrade.FAIR:
        return this.intl.t(
          'job.odds_of_hiring.hiring_forecast.modifier.grade_fair'
        );
      case ModifierGrade.POOR:
        return this.intl.t(
          'job.odds_of_hiring.hiring_forecast.modifier.grade_poor'
        );
    }
  }

  get modifierColor() {
    switch (this.modifierGrade) {
      case ModifierGrade.GOOD:
        return 'green';
      case ModifierGrade.FAIR:
        return 'yellow';
      case ModifierGrade.POOR:
        return 'rose';
    }
  }
}
