/* import __COLOCATED_TEMPLATE__ from './ziggeo.hbs'; */
import { initZiggeoScriptTag } from './../utils/ziggeo';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { bind } from '@ember/runloop';
import TimeFormatService from 'teamtailor/services/time-format';
import TtZiggeo from 'teamtailor/services/tt-ziggeo';

type Ziggeo = {
  activate: () => void;
  destroy: () => void;
  get(value: string): string;
  on(event: string, cb: () => void): void;
};

type ZiggeoArgs = {
  aspectRatio?: number;
  autoplay?: boolean;
  hasRecording?: boolean;
  height?: number;
  minHeight?: number | string;
  minWidth?: number | string;
  onRerecord?: () => void;
  onVerified?(videoToken: string): void;
  videoToken?: string;
  width?: number | string;
  id?: number | string;
};

export default class ZiggeoComponent extends Component<ZiggeoArgs> {
  declare ziggeo: Ziggeo;
  @service declare timeFormat: TimeFormatService;
  @service declare ttZiggeo: TtZiggeo;
  @tracked showRecordButton = false;
  @tracked isRecording = false;
  @tracked isPaused = false;

  @action
  async handleDidInsert(el: HTMLElement): Promise<void> {
    const scriptTagPromise = initZiggeoScriptTag(this.timeFormat.locale);
    await scriptTagPromise;
    let clientAuthToken = await this.ttZiggeo.clientAuthTokenDashboardWidget(
      String(this.args.id)
    );

    let attrs = {
      allowupload: false,
      aspectratio: this.args.aspectRatio,
      autoplay: this.args.autoplay,
      heigth: this.args.height,
      minheight: this.args.minHeight || 0,
      minwidth: this.args.minWidth || 0,
      pausable: true,
      picksnapshots: false,
      playermodeifexists: true,
      rerecordable: this.args.hasRecording,
      rerecordableifexists: this.args.hasRecording,
      showduration: true,
      skipinitial: true,
      themecolor: 'red',
      timelimit: 300,
      video: this.args.videoToken,
      width: this.args.width || '100%',
      volume: this.args.autoplay ? 0 : 1,
      'flip-camera': true,
      'client-auth': clientAuthToken || '',
    };

    if (this.args.videoToken) {
      this.ziggeo = new ZiggeoApi.V2.Player({
        element: el,

        attrs,
      });
    } else {
      this.ziggeo = new ZiggeoApi.V2.Recorder({
        element: el,

        attrs,
      });
    }

    this.ziggeo.activate();

    this.ziggeo.on(
      'verified',
      bind(this, () => {
        // eslint-disable-next-line ember/use-ember-get-and-set
        const videoToken = this.ziggeo.get('video');
        if (this.args.onVerified && this.args.hasRecording) {
          this.args.onVerified(videoToken);
        }
      })
    );

    this.ziggeo.on(
      'rerecord',
      bind(this, () => {
        if (this.args.onRerecord) {
          this.args.onRerecord();
        }
      })
    );
  }

  willDestroy(): void {
    super.willDestroy();
    this.ziggeo.destroy();
  }
}
