/* import __COLOCATED_TEMPLATE__ from './user.hbs'; */
import Component from '@glimmer/component';
import { UserModel } from 'teamtailor/models';
import { get } from 'teamtailor/utils/get';

interface HiringTeamUserArgs {
  recruiter: UserModel;
  user: UserModel;
  showTitle?: boolean;
}

export default class HiringTeamUser extends Component<HiringTeamUserArgs> {
  get user() {
    return this.args.user;
  }

  get recruitingFirm() {
    return get(this.user, 'recruitingFirm');
  }

  get title() {
    if (!this.args.showTitle) {
      return '';
    }

    return get(this.user, 'title') || get(this.recruitingFirm, 'name');
  }
}
