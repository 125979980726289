/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import InsightsService from 'teamtailor/services/insights';
import { gql } from '@apollo/client/core';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import FlipperService from 'teamtailor/services/flipper';
import { StageModel } from 'teamtailor/models';

const QUERY = gql`
  query OddsOfHiringQuery($jobId: ID!) {
    similarJobs: oddsOfHiringQuery(jobId: $jobId, similarJobs: true) {
      weeklyOdds: odds(frequency: WEEKLY) {
        numDays
        week: index
        frequency
        modifiers {
          key
          value
        }
        value
      }
      averageTimeToHire
    }
  }
`;

interface OddsOfHiringInterface {
  jobId: string;
  stage: StageModel;
}

export type ModifierType = 'CONFIDENCE' | 'TIME_IN_STAGE';

export type Modifier = {
  key: ModifierType;
  value: number;
};

export type OddsType = {
  numDays: number;
  month?: number;
  week?: number;
  frequency: 'MONTHLY' | 'WEEKLY';
  modifiers: Modifier[];
  value: number;
};

interface OddsOfHiringQueryResult {
  similarJobs: {
    weeklyOdds: OddsType[];
    averageTimeToHire: number;
  };
}

export default class OddsOfHiringComponent extends Component<OddsOfHiringInterface> {
  @service declare insights: InsightsService;
  @service declare flipper: FlipperService;

  @tracked weeklyOdds: OddsType[] = [];
  @tracked averageTimeToHire = 0;

  get shouldShowOddsOfHiring() {
    return (
      (this.job.isPublished || this.job.isUnlisted) &&
      this.job.isPublic &&
      this.hasEnoughStages &&
      !this.fetchOddsOfHiringTask.isRunning
    );
  }

  get canShowForecast() {
    return this.hasRequiredNumberOfApplications && this.hasForecast;
  }

  get job() {
    return this.args.stage.job;
  }

  get hasRequiredNumberOfApplications() {
    const activeStages = this.job.stages.filter((stage) => !stage.hired);
    const totalActiveApplications = activeStages.reduce((acc, stage) => {
      const applicationCount = stage.activeJobApplications.length;
      return acc + applicationCount;
    }, 0);

    return totalActiveApplications >= 1;
  }

  get hasEnoughStages() {
    return this.job.stages.length >= 5;
  }

  get hasForecast() {
    return this.weeklyOdds.length > 0;
  }

  fetchOdds = task(async () => {
    const { jobId } = this.args;
    const result = await this.insights.query<OddsOfHiringQueryResult>({
      query: QUERY,
      variables: {
        jobId,
      },

      context: { jobId },
    });

    const { weeklyOdds, averageTimeToHire } = result.similarJobs;

    this.averageTimeToHire = averageTimeToHire;
    this.weeklyOdds = weeklyOdds;
  });

  fetchOddsOfHiringTask = trackedTask(this, this.fetchOdds, () => [
    this.args.jobId,
  ]);
}
