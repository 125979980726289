/* import __COLOCATED_TEMPLATE__ from './weekly-hiring-forecast.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { OddsType } from 'teamtailor/components/stage/odds-of-hiring/index';
import moment from 'moment-timezone';

interface HiringForecastArgs {
  odds: OddsType[];
}

export default class WeeklyHiringForecastComponent extends Component<HiringForecastArgs> {
  @service declare intl: IntlService;

  @tracked selectedWeek: number = 4;
  @tracked isShowMoreInfo: boolean = false;

  get today(): String {
    return moment().startOf('day').format('YYYY-MM-DD');
  }

  get weeklyOdds() {
    return this.args.odds;
  }

  get options() {
    return this.weeklyOdds.map((odd) => odd.week);
  }

  get selectedOption() {
    return this.weeklyOdds[this.selectedWeek - 1] || null;
  }

  get estimatePercentage() {
    if (!this.selectedOption) return 0;

    const estimatedPercentage = this.selectedOption.value * 100;

    if (estimatedPercentage > 95) {
      return 95;
    }

    return estimatedPercentage.toFixed(0);
  }

  get estimateColor() {
    return this.oddsColor(this.selectedOption);
  }

  get modifiers() {
    return this.args.odds[0]?.modifiers || [];
  }

  oddsColor(odds: OddsType | null) {
    if (!odds) return 'zink';

    if (odds.value >= 0.9) {
      return 'green';
    } else if (odds.value >= 0.5) {
      return 'yellow';
    } else {
      return 'red';
    }
  }

  oddsForWeek(week: number) {
    return this.weeklyOdds.find((odd) => odd.week === week) || null;
  }

  @action
  weeksAsText(odds: OddsType) {
    return this.intl.t('common.x_weeks', { count: odds.week });
  }

  @action
  onSelectOption(selectedWeek: number) {
    this.selectedWeek = selectedWeek;
  }

  @action
  textForWeek(week: number) {
    const odds = this.oddsForWeek(week);
    if (!odds) return '';

    return this.weeksAsText(odds);
  }

  @action
  oddsColorForWeek(week: number) {
    const odds = this.oddsForWeek(week);

    return this.oddsColor(odds);
  }
}
